/* eslint no-undef: 0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('jquery');
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('@fortawesome/fontawesome-free');
require('@pnotify/core');
require('bootstrap');
require('overlayscrollbars/js/jquery.overlayScrollbars.min');
require('admin-lte');
require('jquery-mousewheel/jquery.mousewheel');
require('raphael/raphael.min');
